import * as React from "react"
import { useState } from "react"
import CategoryList from "../components/category-list"
import "../styles/global.css";
import { graphql } from "gatsby";
import Layout from "../components/layout";

export const query = graphql`
  query IndexPageQuery {
    allCategoriesJson {
      edges {
        node {
          categoryId
          name
          lead
          keywords
          slug
          image {
            src {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          name
          }
        }
      }
    }
    allSolutionsJson {
      edges {
        node {
          categories
          company
          productName
        }
      }
    }
  }
  `

// markup
const IndexPage = ({ data }) => {
  const categoryData = data.allCategoriesJson.edges;
  const solutionData = data.allSolutionsJson.edges;
  const [searchString, setSearchString] = useState<string>('');

  const onSearchInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value.toLowerCase());
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  return (
    <Layout>
      <div className="container mx-auto">
        <div>
          <h1 className="mx-8 mt-8">
            {process.env.GATSBY_LANGUAGE === "no" ?
              "Samlingsplassen for skikkelig gode forretningssystem og løsninger" : "Branschlösningar.se - Samlingsplatsen för riktigt bra lösningar - Hitta rätt affärssystem och lösning för din bransch"
            }
          </h1>
          <p className="mx-8">
            {process.env.GATSBY_LANGUAGE === "no" ? `Bransjeløsninger.no har sammenstilt Microsoft Dynamics forretningssystem, CRM-system og tilleggsløsninger fra anerkjente leverandører. Systemene selges og leveres av sertifiserte IT-konsulenter med de rette kunnskapene for nettopp din virksomhet. Løsningene er delt inn etter bransje/område. Nedenfor kan du velge om du leter etter et komplett forretningssystem eller CRM-system utarbeidet for nettopp din bransje av spesialiserte leverandører, eller om du leter etter tilleggsløsninger og addons som løfter funksjonaliteten i ditt eksisterende ERP- eller CRM- system.` : `Branschlösningar.se har sammanställt affärssystem och
            branschlösningar från erkänt duktiga MICROSOFT-, VISMA-, NETSUITE-,
            JEEVES- och IFS-partners. De arbetar med kunder inom just din
            bransch och har en lösning som vänder sig till just den bransch du
            arbetar i. Detta gör att den partner du väljer känner till din
            vardag och de utmaningar som är specifika för just din bransch. Välj
            bransch eller typ av lösning nedan.`}

          </p>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '3em 0' }}
        >
          <input
            type="text"
            placeholder={process.env.GATSBY_LANGUAGE === "no" ? "Jeg ser etter..." : "Sök bransch..."}
            className="text-xl shadow-md rounded-md h-16 w-96 max-w-3/4 px-4 focus:outline-none focus:ring focus:border-blue-300 border border-solid border-gray-300"
            onChange={onSearchInputChange}
            onKeyUp={onEnter}
          />
        </div>
        <CategoryList
          data={categoryData.filter((x) => {
            const keywords = x.node.keywords.some((y) =>
              y.toLowerCase().includes(searchString.toLowerCase()),
            );
            const solutions = solutionData.find(
              (z) =>
                z.node.categories.find((cat) => cat === x.node.categoryId) &&
                (z.node.company.toLowerCase().includes(searchString.toLowerCase()) ||
                  z.node.productName
                    .toLowerCase()
                    .includes(searchString.toLowerCase())),
            );
            return keywords || solutions;
          })}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
