import React from 'react';
import { getImage, GatsbyImage, ImageDataLike } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

interface Props {
    data: { node: { categoryId: number, name: string, lead: string, slug: string, keywords: string[], image: { name: string, src: ImageDataLike } } }[];
}

const CategoryList = ({ data }: Props) => {

    return (
        <>
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6 mx-6 my-8">
                {data?.map((category) => (
                    <li
                        key={category.node.categoryId}
                        className="flex flex-row shadow-md rounded-md bg-gray-100 border border-opacity-0 border-gray-200 hover:bg-white hover:border-opacity-100 cursor-pointer"
                    >
                        <Link
                            to={`/${category.node.slug}`}
                            className="flex items-center w-full focus:outline-none rounded-md focus:ring focus:border-blue-300 focus:bg-white"
                        >
                            <GatsbyImage image={getImage(category.node.image.src)} alt={category.node.image.name} loading='eager' className="w-20 mr-2 rounded-l-md inline-block" />
                            <span>{category.node.name}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default CategoryList;
